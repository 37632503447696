export const useMergeJson = () => {
  function mergeMissingKeys<T extends Record<string, any>>(obj1: T, obj2: T): T {
    const result: T = JSON.parse(JSON.stringify(obj1))
    const sourceCopy: T = JSON.parse(JSON.stringify(obj2))

    // console.log({ sourceCopy })

    function mergeRecursive(target: Record<string, any>, source: Record<string, any>): void {
      // console.log({ source, target })
      for (const key in source) {
        // console.log({ key })
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          if (typeof source[key] === 'object' && source[key] !== null) {
            if (typeof target[key] === 'object' && target[key] !== null) {
              // console.log({ targetKey: target[key], sourceKey: source[key] })
              mergeRecursive(target[key], source[key])
            } else {
              target[key] = source[key]
            }
          } else if (!(key in target)) {
            target[key] = source[key]
          }
        }
      }
    }

    mergeRecursive(result, sourceCopy)
    return result
  }

  return {
    mergeMissingKeys,
  }
}
