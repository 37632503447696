import { default as indexsU5h6mS7UZMeta } from "/vercel/path0/pages/embed/[[embedContentType]]/[contentId]/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexVdLfgn2LyoMeta } from "/vercel/path0/pages/preview/[landingPageId]/[purlId]/index.vue?macro=true";
import { default as indexkYet0yXaOzMeta } from "/vercel/path0/pages/preview/chapter/[id]/index.vue?macro=true";
import { default as indexEur8Gd118YMeta } from "/vercel/path0/pages/preview/landingPage/[landingId]/index.vue?macro=true";
import { default as _91sceneIndex_939YhuLHQiCDMeta } from "/vercel/path0/pages/preview/poster/[purlId]/[sceneIndex].vue?macro=true";
import { default as _91chapterId_93DuafZJgifkMeta } from "/vercel/path0/pages/preview/poster2/[chapterId].vue?macro=true";
import { default as _91sceneId_93fULiFIlK5jMeta } from "/vercel/path0/pages/preview/sceneLayout/[sceneId].vue?macro=true";
import { default as _91sceneIndex_93BDEt98R5NTMeta } from "/vercel/path0/pages/preview/thumbnail/[chapterId]/[sceneIndex].vue?macro=true";
import { default as indexBn1HP9XClOMeta } from "/vercel/path0/pages/PURL/[landingId]/[purlId]/index.vue?macro=true";
import { default as indexpSDo2R62imMeta } from "/vercel/path0/pages/PURL/[landingId]/index.vue?macro=true";
import { default as _91chapterID_93xfHsDx4WNSMeta } from "/vercel/path0/pages/video/chapter/[chapterID].vue?macro=true";
import { default as _91chapterID_93PcOOj2jaLlMeta } from "/vercel/path0/pages/video/test/[chapterID].vue?macro=true";
export default [
  {
    name: indexsU5h6mS7UZMeta?.name ?? "embed-embedContentType-contentId",
    path: indexsU5h6mS7UZMeta?.path ?? "/embed/:embedContentType?/:contentId()",
    meta: indexsU5h6mS7UZMeta || {},
    alias: indexsU5h6mS7UZMeta?.alias || [],
    redirect: indexsU5h6mS7UZMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[[embedContentType]]/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexVdLfgn2LyoMeta?.name ?? "preview-landingPageId-purlId",
    path: indexVdLfgn2LyoMeta?.path ?? "/preview/:landingPageId()/:purlId()",
    meta: indexVdLfgn2LyoMeta || {},
    alias: indexVdLfgn2LyoMeta?.alias || [],
    redirect: indexVdLfgn2LyoMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/[landingPageId]/[purlId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkYet0yXaOzMeta?.name ?? "preview-chapter-id",
    path: indexkYet0yXaOzMeta?.path ?? "/preview/chapter/:id()",
    meta: indexkYet0yXaOzMeta || {},
    alias: indexkYet0yXaOzMeta?.alias || [],
    redirect: indexkYet0yXaOzMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/chapter/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEur8Gd118YMeta?.name ?? "preview-landingPage-landingId",
    path: indexEur8Gd118YMeta?.path ?? "/preview/landingPage/:landingId()",
    meta: indexEur8Gd118YMeta || {},
    alias: indexEur8Gd118YMeta?.alias || [],
    redirect: indexEur8Gd118YMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/landingPage/[landingId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91sceneIndex_939YhuLHQiCDMeta?.name ?? "preview-poster-purlId-sceneIndex",
    path: _91sceneIndex_939YhuLHQiCDMeta?.path ?? "/preview/poster/:purlId()/:sceneIndex()",
    meta: _91sceneIndex_939YhuLHQiCDMeta || {},
    alias: _91sceneIndex_939YhuLHQiCDMeta?.alias || [],
    redirect: _91sceneIndex_939YhuLHQiCDMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/poster/[purlId]/[sceneIndex].vue").then(m => m.default || m)
  },
  {
    name: _91chapterId_93DuafZJgifkMeta?.name ?? "preview-poster2-chapterId",
    path: _91chapterId_93DuafZJgifkMeta?.path ?? "/preview/poster2/:chapterId()",
    meta: _91chapterId_93DuafZJgifkMeta || {},
    alias: _91chapterId_93DuafZJgifkMeta?.alias || [],
    redirect: _91chapterId_93DuafZJgifkMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/poster2/[chapterId].vue").then(m => m.default || m)
  },
  {
    name: _91sceneId_93fULiFIlK5jMeta?.name ?? "preview-sceneLayout-sceneId",
    path: _91sceneId_93fULiFIlK5jMeta?.path ?? "/preview/sceneLayout/:sceneId()",
    meta: _91sceneId_93fULiFIlK5jMeta || {},
    alias: _91sceneId_93fULiFIlK5jMeta?.alias || [],
    redirect: _91sceneId_93fULiFIlK5jMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/sceneLayout/[sceneId].vue").then(m => m.default || m)
  },
  {
    name: _91sceneIndex_93BDEt98R5NTMeta?.name ?? "preview-thumbnail-chapterId-sceneIndex",
    path: _91sceneIndex_93BDEt98R5NTMeta?.path ?? "/preview/thumbnail/:chapterId()/:sceneIndex()",
    meta: _91sceneIndex_93BDEt98R5NTMeta || {},
    alias: _91sceneIndex_93BDEt98R5NTMeta?.alias || [],
    redirect: _91sceneIndex_93BDEt98R5NTMeta?.redirect,
    component: () => import("/vercel/path0/pages/preview/thumbnail/[chapterId]/[sceneIndex].vue").then(m => m.default || m)
  },
  {
    name: indexBn1HP9XClOMeta?.name ?? "PURL-landingId-purlId",
    path: indexBn1HP9XClOMeta?.path ?? "/PURL/:landingId()/:purlId()",
    meta: indexBn1HP9XClOMeta || {},
    alias: indexBn1HP9XClOMeta?.alias || [],
    redirect: indexBn1HP9XClOMeta?.redirect,
    component: () => import("/vercel/path0/pages/PURL/[landingId]/[purlId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpSDo2R62imMeta?.name ?? "PURL-landingId",
    path: indexpSDo2R62imMeta?.path ?? "/PURL/:landingId()",
    meta: indexpSDo2R62imMeta || {},
    alias: indexpSDo2R62imMeta?.alias || [],
    redirect: indexpSDo2R62imMeta?.redirect,
    component: () => import("/vercel/path0/pages/PURL/[landingId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91chapterID_93xfHsDx4WNSMeta?.name ?? "video-chapter-chapterID",
    path: _91chapterID_93xfHsDx4WNSMeta?.path ?? "/video/chapter/:chapterID()",
    meta: _91chapterID_93xfHsDx4WNSMeta || {},
    alias: _91chapterID_93xfHsDx4WNSMeta?.alias || [],
    redirect: _91chapterID_93xfHsDx4WNSMeta?.redirect,
    component: () => import("/vercel/path0/pages/video/chapter/[chapterID].vue").then(m => m.default || m)
  },
  {
    name: _91chapterID_93PcOOj2jaLlMeta?.name ?? "video-test-chapterID",
    path: _91chapterID_93PcOOj2jaLlMeta?.path ?? "/video/test/:chapterID()",
    meta: _91chapterID_93PcOOj2jaLlMeta || {},
    alias: _91chapterID_93PcOOj2jaLlMeta?.alias || [],
    redirect: _91chapterID_93PcOOj2jaLlMeta?.redirect,
    component: () => import("/vercel/path0/pages/video/test/[chapterID].vue").then(m => m.default || m)
  }
]