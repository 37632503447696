import { Duration } from 'luxon'
import { ELEMENT_TYPE } from 'adificial-common/dist/enums/adstudio'

const reducer = (duration, scene) => duration + scene.duration

export default {
  activeScene(state, getters, rootState, rootGetters) {
    return rootGetters['editor/activeScene']
  },
  chapter(state, getters, rootState, rootGetters) {
    return rootGetters['editor/chapter']
  },
  scenes(state, getters, rootState, rootGetters) {
    return rootGetters['editor/scenes']
  },
  availableFonts(state) {
    return state.availableFonts
  },
  availableGoogleFonts(_, getters) {
    return getters.availableFonts?.googleFonts
  },
  availableGoogleFontsItems(_, getters) {
    return getters.availableGoogleFonts?.items
  },
  loadedFonts(state) {
    return state.loadedFonts
  },
  playbackStatus(state) {
    return state.playbackStatus
  },
  lastSceneIndex: (state, getters, rootState, rootGetters) => rootGetters['editor/scenes'].length - 1,
  activeBackground(_, getters) {
    const activeBackground = getters.currentlyPlayingScene
      ? getters.currentlyPlayingScene.elements.find((el) => el.type === ELEMENT_TYPE.BACKGROUND)
      : undefined
    return activeBackground
  },
  transform(state, getters, rootState, rootGetters) {
    return rootGetters['editor/chapter']?.transform
  },
  scenesToPlay(state) {
    return state.playbackStatus.scenesToPlay
  },
  transitionsToPlay(state) {
    return state.playbackStatus.transitionsToPlay
  },
  currentlyPlayingScene(state) {
    return state.playbackStatus.scenesToPlay[state.playbackStatus.currentSceneIndex]
  },
  currentlyPlayingSceneIndex(state) {
    return state.playbackStatus.currentSceneIndex
  },
  nextSceneToPlay(state, getters) {
    return state.playbackStatus.scenesToPlay[getters.nextSceneToPlayIndex]
  },
  nextSceneToPlayIndex(state) {
    const nextSceneToPlayIndex =
      state.playbackStatus.currentSceneIndex + 1 < state.playbackStatus.scenesToPlay.length
        ? state.playbackStatus.currentSceneIndex + 1
        : undefined
    return nextSceneToPlayIndex
  },
  currentlyPlayingElements(_, getters) {
    return getters.currentlyPlayingScene?.elements?.filter((el) => el.playing)
  },
  isPlayingContent(state) {
    return state.playbackStatus.playing
  },
  playerVolume(state) {
    return state.playbackStatus.playerVolume
  },
  isPlayingLastScene(getters) {
    return getters.isPlayingContent && getters.currentlyPlayingSceneIndex === getters.scenesToPlay.length - 1
  },
  shouldShowScene(_, getters) {
    return (scene) => {
      return getters.isPlayingContent ? scene.playing || getters.nextSceneToPlay === scene : scene.active
    }
  },
  aspectRatio(state) {
    const aspectRatio = state.chapter.width / state.chapter.height
    if (aspectRatio === 1) {
      return '1:1'
    } else if (aspectRatio === 16 / 9) {
      return '16:9'
    } else if (aspectRatio === 4 / 3) {
      return '4:3'
    } else {
      return 'N/A'
    }
  },
  content(state) {
    return state.chapter
  },
  contentType(state) {
    return state.contentType
  },
  sceneLayout(state) {
    return state.sceneLayout
  },
  currentTimeAsDuration(state) {
    return Duration.fromObject(state.playbackStatus.currentTime)
  },
  playbackDurationInSeconds(_, getters) {
    return getters.scenesToPlay?.reduce(
      (totalDuration, scene, sceneIndex) =>
        totalDuration +
        scene.duration -
        (getters.transitionsToPlay?.[sceneIndex]?.outSceneAnimation?.duration / 1000 || 0),
      0,
    )
  },
  playbackDurationAsDuration(_, getters) {
    return Duration.fromObject({
      seconds: getters.playbackDurationInSeconds,
    })
  },
  getSceneStartTime(state, getters) {
    return (sceneIndex) => getters.getSceneEndTime(sceneIndex) - state.playbackStatus.scenesToPlay[sceneIndex]?.duration
  },
  getSceneEndTime(state, getters) {
    return (sceneIndex) =>
      state.playbackStatus.scenesToPlay.slice(0, sceneIndex + 1).reduce((duration, scene, index) => {
        return (
          duration + scene.duration - (getters.sceneTransitions?.[index - 1]?.outSceneAnimation?.duration / 1000 || 0)
        )
      }, 0)
  },
  getSceneIndex(_state, _getters, _rootState, rootGetters) {
    return rootGetters['editor/getSceneIndex']
  },
  sceneTransitions(_state, _getters, _rootState, rootGetters) {
    return rootGetters['editor/sceneTransitions']
  },

  chapterTotalDurationInSeconds(_, getters) {
    return getters.scenes.slice(0, getters.scenes.length).reduce((duration, scene, index) => {
      return (
        duration + scene.duration - (getters.sceneTransitions?.[index - 1]?.outSceneAnimation?.duration / 1000 || 0)
      )
    }, 0)
  },
  chapterTotalDurationAsDuration(_, getters) {
    return Duration.fromObject({ seconds: getters.chapterTotalDurationInSeconds })
  },
  streamingElements(state) {
    return state.playbackStatus.streamingElements
  },
  bufferingProgress(state) {
    return state.playbackStatus.bufferingProgress
  },
  loadedScenes: (state) => state.playbackStatus.loadedScenes,
  timeoutReached: (state) => state.playbackStatus.timeoutReached,
  progressTimer: (state) => state.playbackStatus.progressTimer,
  retries: (state) => state.playbackStatus.retries,
  loadingSceneTimer: (state) => state.playbackStatus.loadingSceneTimer,
  stalled: (state) => state.playbackStatus.stalled,
  stalledRetries: (state) => state.playbackStatus.stalledRetries,
  previewing: (state) => state.playbackStatus.previewing,
  getDatapointDefaultValues: (state, _getters, _rootState, rootGetters) =>
    state.defaultValues || rootGetters['editor/getDatapointDefaultValues'],
}
