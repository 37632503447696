import { defaultChapter } from 'adificial-common/dist/defaults'
export default () => ({
  baseURL: '',
  chapter: defaultChapter(),
  availableFonts: {
    googleFonts: undefined,
    customFonts: undefined,
  },
  loadedFonts: [],
  playbackStatus: {
    currentSceneIndex: undefined,
    currentTime: {
      seconds: 0,
    },
    playing: false,
    previewing: false,
    paused: false,
    streamingElements: [],
    bufferingProgress: {},
    onUpdateCurrentTime: () => {},
    scenesToPlay: [],
    transitionsToPlay: [],
    updateCurrentTimeIntervalID: undefined,
    loadedScenes: [],
    loadingSceneTimer: null,
    timeoutReached: false,
    retries: 1,
    stalled: false,
    stalledRetries: 0,
    playerVolume: 1,
  },
})
