import { Duration } from 'luxon'
import {
  PLAYBACK_REFRESH_RATE,
  MAX_WAIT_FOR_SCENE_LOAD,
  MAX_STALLED_RETRIES,
} from 'adificial-common/dist/util/platform'

export default {
  async playActiveScene({ getters, dispatch }) {
    const activeScene = getters.activeScene
    await dispatch('playScenes', [activeScene])
  },

  async playAllScenes({ dispatch, getters }) {
    await dispatch('playScenes', getters.scenes)
  },

  async playAllFromActiveScene({ dispatch, getters, rootGetters }) {
    const activeSceneIndex = rootGetters['editor/activeSceneIndex']

    const scenesToPlay = getters.scenes.slice(activeSceneIndex)

    await dispatch('playScenes', scenesToPlay)
  },

  async playScenes({ commit, dispatch, getters }, scenesToPlay) {
    const firstSceneIndex = getters.getSceneIndex(scenesToPlay[0])
    const lastSceneIndex = getters.getSceneIndex(scenesToPlay[scenesToPlay.length - 1])
    const transitionsToPlay = getters.sceneTransitions?.slice(firstSceneIndex, lastSceneIndex) || []
    commit('initializePlayback', { scenesToPlay, transitionsToPlay })
    await dispatch('playNextSceneInQueue')
  },

  async playNextSceneInQueue({ commit, getters, dispatch }) {
    commit('nextCurrentSceneIndex')
    if (getters.currentlyPlayingScene) {
      if (getters.loadedScenes.includes(getters.currentlyPlayingSceneIndex) || getters.previewing) {
        await dispatch('startPlayback')
      } else {
        commit('setStalled', true)
        if (getters.currentlyPlayingSceneIndex !== 0) {
          await dispatch('pausePlayback')
        }
        const timer = setInterval(async () => {
          if (getters.loadedScenes.includes(getters.currentlyPlayingSceneIndex)) {
            commit('setStalled', false)
            if (getters.currentlyPlayingSceneIndex === 0) {
              await dispatch('startPlayback')
            } else {
              await dispatch('resumePlayback')
            }
            clearInterval(getters.loadingSceneTimer)
          } else {
            commit('setStalledRetries', getters.stalledRetries + 1)
            if (getters.stalledRetries >= MAX_STALLED_RETRIES) {
              commit('setStalled', false)
              commit('setStalledRetries', 0)
              commit('addLoadedScene', getters.currentlyPlayingSceneIndex)
            }
            console.log(`Waiting for scene ${getters.currentlyPlayingSceneIndex} to be loaded`)
          }
        }, MAX_WAIT_FOR_SCENE_LOAD)
        commit('setLoadingSceneTimer', timer)
      }
    } else {
      dispatch('stopPlayback')
    }
  },

  async playNextScene({ state, dispatch }) {
    const newCurrentSceneIndex = Math.min(
      state.playbackStatus.scenesToPlay.length - 1,
      state.playbackStatus.currentSceneIndex + 1,
    )
    await dispatch('moveToSceneAtIndex', { sceneIndex: newCurrentSceneIndex })
  },

  async playPreviousScene({ state, dispatch }) {
    const newCurrentSceneIndex = Math.max(0, state.playbackStatus.currentSceneIndex - 1)
    await dispatch('moveToSceneAtIndex', { sceneIndex: newCurrentSceneIndex })
  },

  async playSceneAtIndex({ commit, dispatch, getters }, sceneIndex) {
    await dispatch('stopPlayback')
    commit('setCurrentSceneIndex', sceneIndex)

    const startOfSceneAtIndex = Duration.fromObject({ seconds: getters.getSceneStartTime(sceneIndex) })
    commit('setCurrentTime', startOfSceneAtIndex.toObject())
    await dispatch('startPlayback')
  },

  async moveToSceneAtIndex({ commit, dispatch, getters }, { sceneIndex, forcePlay = false }) {
    const wasPlayingContent = getters.isPlayingContent
    await dispatch('stopPlayback')
    commit('setCurrentSceneIndex', sceneIndex)
    const startOfSceneAtIndex = Duration.fromObject({ seconds: getters.getSceneStartTime(sceneIndex) })
    commit('setCurrentTime', startOfSceneAtIndex.toObject())

    if (forcePlay || wasPlayingContent) {
      await dispatch('startPlayback')
    }
  },

  async startPlayback({ commit, getters, dispatch }) {
    if (getters.currentlyPlayingScene) {
      if (!getters.currentlyPlayingScene.playing) await dispatch('startScenePlayback', getters.currentlyPlayingScene)
      if (!getters.isPlayingContent) {
        commit('startContentPlayback', () => {
          const newTime = getters.currentTimeAsDuration.plus(
            Duration.fromObject({ milliseconds: PLAYBACK_REFRESH_RATE }),
          )
          commit('setCurrentTime', newTime.toObject())

          // Current Scene Timeout
          const sceneEnd = Math.floor(getters.getSceneEndTime(getters.currentlyPlayingSceneIndex) * 1000)
          if (newTime.as('milliseconds') >= sceneEnd) {
            dispatch('onSceneTimeout', getters.currentlyPlayingScene)
          }

          const nextSceneToPlay = getters.nextSceneToPlay
          if (nextSceneToPlay && !nextSceneToPlay.playing) {
            // Handle transitions
            const outTransitionIndex = getters.currentlyPlayingSceneIndex
            const outTransition = getters.transitionsToPlay[outTransitionIndex]
            const outSceneAnimationName = outTransition?.outSceneAnimation?.name
            if (outTransition && outSceneAnimationName !== 'none') {
              const outTransitionDuration = outTransition?.outSceneAnimation?.duration
              const outTransitionStart = Duration.fromObject({
                seconds: getters.getSceneEndTime(getters.currentlyPlayingSceneIndex),
              }).minus(Duration.fromObject({ milliseconds: outTransitionDuration }))
              if (newTime >= outTransitionStart) {
                dispatch('startScenePlayback', nextSceneToPlay)
              }
            }
          }
        })
      }
    }
  },

  async stopPlayback({ commit, getters, dispatch }, { resetPlayback = false, resetToSceneStart = false } = {}) {
    if (getters.currentlyPlayingScene) {
      await dispatch('stopScenePlayback', getters.currentlyPlayingScene)
    }
    commit('endContentPlayback')
    if (resetPlayback) {
      commit('initializePlayback', { scenesToPlay: [], transitionsToPlay: [] })
    }
    if (resetToSceneStart) {
      const startOfSceneAtIndex = Duration.fromObject({
        seconds: getters.getSceneStartTime(getters.currentlyPlayingSceneIndex),
      })
      commit('setCurrentTime', startOfSceneAtIndex.toObject())
    }
  },

  async pausePlayback({ getters, dispatch, commit }, waitForElement) {
    commit('setPlaybackPaused', waitForElement)
    if (getters.currentlyPlayingScene) {
      await dispatch('pauseScenePlayback', getters.currentlyPlayingScene)
    }
  },

  async resumePlayback({ getters, dispatch, commit }) {
    if (getters.currentlyPlayingScene) {
      await dispatch('resumeScenePlayback', getters.currentlyPlayingScene)
    }
    commit('setPlaybackResumed')
  },

  async streamLoaded({ getters, commit, dispatch }, waitForElement) {
    const resume = getters.streamingElements.includes(waitForElement)
    if (resume && getters.playbackStatus.paused) {
      commit('removeStreamingElement', waitForElement)
      await dispatch('resumePlayback')
    }
  },

  startScenePlayback({ commit }, scene) {
    scene.elements.forEach((el) => commit('startElementPlayback', el))
    commit('startScenePlayback', scene)
  },

  pauseScenePlayback({ commit, getters, rootGetters }, scene) {
    for (const el of scene.elements) {
      commit('pauseElementPlayback', el)
    }
    commit('pauseScene', scene)

    const isEditing = rootGetters['editor/isEditing']
    const { currentlyPlayingSceneIndex, currentlyPlayingScene, getSceneIndex } = getters

    if (!isEditing) {
      commit('editor/setActiveScene', currentlyPlayingSceneIndex, { root: true })
      return
    }

    const scenesToPlay = rootGetters['adstudio/scenesToPlay']
    const currentActiveScene = rootGetters['editor/activeSceneIndex']
    const playingSceneIndex = getSceneIndex(currentlyPlayingScene)

    const activeSceneIndex = scenesToPlay.length > 1 ? playingSceneIndex : currentActiveScene
    commit('editor/setActiveScene', activeSceneIndex, { root: true })
  },

  async resumeScenePlayback({ commit }, scene) {
    await scene.elements.forEach(async (el) => await commit('resumeElementPlayback', el))
    commit('resumeScene', scene)
  },

  stopScenePlayback({ commit }, scene) {
    scene.elements.forEach((el) => commit('endElementPlayback', el))
    commit('endScenePlayback', scene)
  },

  async onSceneTimeout({ dispatch }, scene) {
    await dispatch('stopScenePlayback', scene)
    await dispatch('playNextSceneInQueue')
  },

  fetchFonts({ getters, commit }) {
    if (getters.availableGoogleFonts === undefined) {
      fetch('/api/fonts')
        .then((response) => response.json())
        .then(({ data }) => {
          const { googleFonts } = data
          googleFonts.items.forEach((fontItem) => {
            fontItem.loaded = false
            Object.keys(fontItem.files).forEach((variant) => {
              if (!fontItem.files[variant].includes('https:')) {
                fontItem.files[variant] = fontItem.files[variant].replace('http:', 'https:')
              }
            })
          })
          commit('setAvailableGoogleFonts', googleFonts)
        })
    }
  },

  async loadFontByFontFamily({ commit, getters }, fontData) {
    const { $loadFontByFontItem } = useNuxtApp()
    const match = getters.availableGoogleFontsItems?.find((gFontItem) => gFontItem.family === fontData.family)
    const loaded = getters.loadedFonts.filter((f) => f.family === fontData.family)
    if (match && loaded.length === 0) {
      await $loadFontByFontItem(match, fontData)
      commit('addLoadedFont', fontData)
    }
  },

  loadFontPage({ commit, getters, dispatch }, { pageSize = 40, page = 1 } = {}) {
    const start = pageSize * (page - 1)
    const end = pageSize * page
    const itemsToLoad = getters.availableGoogleFontsItems.slice(start, end)

    itemsToLoad.forEach((item) => {
      if (!getters.loadedFonts.find((l) => l.family === item.family)) {
        dispatch('loadFontByFontFamily', {
          family: item.family,
        })
      }
    })
  },
  setDatapointDefaultValues({ commit }, datapointDefaultValues) {
    commit('setDatapointDefaultValues', datapointDefaultValues)
  },
}
