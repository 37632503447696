import { PLAYBACK_REFRESH_RATE } from 'adificial-common/dist/util/platform'

export default {
  setBaseURL(state, url) {
    state.baseURL = url
  },
  setElementAttribute(_, updateAttributeObject) {
    updateAttributeObject.element[updateAttributeObject.attribute] = updateAttributeObject.attributeValue
  },
  setElementStyles(_, { element, newStyles }) {
    element.computedCssStyles = newStyles
  },

  setElementSizeAndPosition(_, { element, x, y, height, width }) {
    element.width = width
    element.height = height
    element.x = x || element.x
    element.y = y || element.y
  },

  initializePlayback(state, { scenesToPlay = [], transitionsToPlay = [] }) {
    state.playbackStatus.playing = false
    state.playbackStatus.scenesToPlay = [...scenesToPlay]
    state.playbackStatus.transitionsToPlay = [...transitionsToPlay]
    state.playbackStatus.currentSceneIndex = undefined
    state.playbackStatus.currentTime = { seconds: 0 }
  },

  setPlaybackPaused(state, waitForElement) {
    state.playbackStatus.playing = false
    state.playbackStatus.paused = true
    if (waitForElement) {
      state.playbackStatus.streamingElements.push(waitForElement)
    }
    clearInterval(state.playbackStatus.updateCurrentTimeIntervalID)
    state.playbackStatus.updateCurrentTimeIntervalID = undefined
  },

  setPlaybackResumed(state) {
    state.playbackStatus.paused = false
    state.playbackStatus.playing = true

    if (!state.playbackStatus.updateCurrentTimeIntervalID) {
      state.playbackStatus.updateCurrentTimeIntervalID = setInterval(
        state.playbackStatus.onUpdateCurrentTime,
        PLAYBACK_REFRESH_RATE,
      )
    }
  },

  pauseElementPlayback(_, element) {
    element.playing = false
    element.paused = true
  },

  resumeElementPlayback(_, element) {
    element.paused = false
    element.playing = true
  },

  removeStreamingElement(state, waitForElement) {
    state.playbackStatus.streamingElements.splice(state.playbackStatus.streamingElements.indexOf(waitForElement), 1)
  },

  endElementPlayback(_, element) {
    element.playing = false
    element.rewind = true
  },

  resetRewind(_, element) {
    element.rewind = false
  },

  startElementPlayback(_, element) {
    element.playing = true
  },

  pauseScene(_, scene) {
    scene.playing = false
    scene.paused = true
  },

  resumeScene(_, scene) {
    scene.paused = false
    scene.playing = true
  },

  endScenePlayback(_, scene) {
    scene.playing = false
  },

  startElementPreview(_, { element, onPreviewEnd, previewAnimationIndex }) {
    element.previewing = true
    element.previewAnimationIndex = previewAnimationIndex
    if (onPreviewEnd !== undefined) element.onPreviewEnd = onPreviewEnd
  },

  endElementPreview(_, { element }) {
    element.previewing = false
    delete element.previewAnimationIndex
    if (element.onPreviewEnd !== undefined) {
      element.onPreviewEnd()
      delete element.onPreviewEnd
    }
  },

  startScenePlayback(_, scene) {
    scene.playing = true
  },

  endContentPlayback(state) {
    state.playbackStatus.playing = false
    clearInterval(state.playbackStatus.updateCurrentTimeIntervalID)
    state.playbackStatus.updateCurrentTimeIntervalID = undefined
  },

  startContentPlayback(state, onUpdateCurrentTime) {
    state.playbackStatus.playing = true
    state.playbackStatus.onUpdateCurrentTime = onUpdateCurrentTime
    if (!state.playbackStatus.updateCurrentTimeIntervalID) {
      state.playbackStatus.updateCurrentTimeIntervalID = setInterval(onUpdateCurrentTime, PLAYBACK_REFRESH_RATE)
    }
  },

  setCurrentSceneIndex(state, newCurrentSceneIndex) {
    state.playbackStatus.currentSceneIndex = newCurrentSceneIndex
  },

  nextCurrentSceneIndex(state) {
    state.playbackStatus.currentSceneIndex =
      state.playbackStatus.currentSceneIndex === undefined ? 0 : state.playbackStatus.currentSceneIndex + 1
  },

  setCurrentTime(state, newCurrentTime) {
    state.playbackStatus.currentTime = newCurrentTime
  },

  setAvailableGoogleFonts(state, googleFonts) {
    state.availableFonts.googleFonts = googleFonts
  },

  addLoadedFont(state, font) {
    state.loadedFonts.push(font)
  },

  setVideoBufferingProgress(state, { src, progress }) {
    state.playbackStatus.bufferingProgress[src] = progress
  },

  setLoadedScenes(state, newScenes) {
    state.playbackStatus.loadedScenes = newScenes
  },
  addLoadedScene(state, scene) {
    state.playbackStatus.loadedScenes.push(scene)
  },
  setTimeoutReached(state, reached) {
    state.playbackStatus.timeoutReached = reached
  },
  addRetry(state) {
    state.playbackStatus.retries = state.playbackStatus.retries + 1
  },
  setLoadingSceneTimer(state, timer) {
    state.playbackStatus.loadingSceneTimer = timer
  },
  setStalled(state, stalled) {
    state.playbackStatus.stalled = stalled
  },
  setStalledRetries(state, retries) {
    state.playbackStatus.stalledRetries = retries
  },
  setIsPreviewing(state, previewing) {
    state.playbackStatus.previewing = previewing
  },
  setDatapointDefaultValues(state, datapointDefaultValues) {
    state.defaultValues = datapointDefaultValues
  },
  setPlayerVolume(state, volume) {
    state.playbackStatus.playerVolume = volume
  },
}
