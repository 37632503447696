import defaultLandingPage from 'adificial-common/dist/defaults/defaultLandingPage'

export default () => ({
  isFetching: false,
  purlData: {},
  baseURL: '',
  landingPage: defaultLandingPage(),
  favicons: null,
  landingPageLoaded: false,
  auth: {
    accessToken: undefined,
    expiration: undefined,
  },
})
