import { CONTENT_TYPE } from 'adificial-common/dist/enums/adstudio'

export default {
  setBaseURL(state, url) {
    state.baseURL = url
  },
  setChapterAudioVolume(state, volume) {
    if (state.chapter?.audio) {
      state.chapter.audio.volume = volume
    }
  },
  setVoiceOverState(state, newState) {
    state.voiceOverState = newState
  },
  setChapterAudioState(state, newState) {
    state.chapterAudioState = newState
  },
  setShowProfileMenu(state, showProfileMenu) {
    state.showProfileMenu = showProfileMenu
  },
  setElementSizeAndPosition(_, { element, x, y, height, width }) {
    element.width = width
    element.height = height
    element.x = x || element.x
    element.y = y || element.y
  },
  setElementAttribute(_, updateAttributeObject) {
    updateAttributeObject.element[updateAttributeObject.attribute] = updateAttributeObject.attributeValue
  },
  setChapter(state, chapter) {
    state.chapter = chapter
  },
  setActiveScene(state, targetIndex) {
    const scenes = state.contentType === CONTENT_TYPE.SCENE_LAYOUT ? [state.sceneLayout] : state.chapter.scenes
    targetIndex = Math.min(scenes.length - 1, targetIndex)
    targetIndex = Math.max(0, targetIndex)
    scenes.forEach((scene, index) => {
      scene.active = index === targetIndex
    })
  },
  setInitialContent(state, initialContent) {
    state.initialContent = JSON.stringify(initialContent)
  },
  setSceneLayout(state, newSceneLayout) {
    state.sceneLayout = newSceneLayout
  },
}
