import { CONTENT_TYPE } from 'adificial-common/dist/enums/adstudio'

export default {
  chapter(state) {
    return state.chapter
  },
  scenes(state) {
    return state.chapter?.scenes || []
  },
  activeScene(state, getters) {
    return state.contentType === CONTENT_TYPE.CHAPTER ? getters.scenes.find((scene) => scene.active) : state.sceneLayout
  },
  activeSceneIndex(state, getters) {
    return getters.scenes.findIndex((scene) => scene.active)
  },
  getSceneIndex(_state, getters) {
    return (scene) => getters.scenes.indexOf(scene)
  },
  sceneVoiceOver(_state, getters) {
    return getters.activeScene?.voiceOver
  },
  sceneVoiceOverByIndex: (_state, getters) => (sceneIndex) => {
    return getters.scenes?.[sceneIndex]?.voiceOver
  },
  voiceOverConfig(state) {
    return state.chapter.voiceOverConfig
  },
  chapterAudioState(state) {
    return state.chapterAudioState
  },
  audioRequest(state) {
    return state.audioRequest
  },
  getDatapointDefaultValues(state) {
    return state.chapter?.defaultValues || {}
  },
  getPreviewDefaults(state) {
    return state.usePreviewDefaults
  },
  labelMapping(_state, getters) {
    return getters.getSelectedConnection?.labelMapping?.map(({ labelId, datapoint }) => {
      return {
        labelId,
        datapoint,
        label: getters.userCompany?.labels?.find((companyLabel) => companyLabel.id === labelId)?.label,
      }
    })
  },
  sceneLayout(state) {
    return state.sceneLayout
  },
  sceneTransitions(state) {
    return state.chapter?.sceneTransitions || []
  },
  isEditing(state) {
    return false
  },
}
