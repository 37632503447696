interface FontData {
  family: string
  style: string
  weight: string
}

function loadGoogleFonts(googleFontsItems: any) {
  const gFontsPromises = googleFontsItems.map((gFontData: any) => {
    const fontPromises = gFontData.variants.map((gfVariant: any) => {
      const fontStyles = mapVariantToStyles(gfVariant)
      const srcURL = `url(${gFontData.files[gfVariant]})`
      const family = navigator.userAgent.includes('Firefox') ? `'${gFontData.family}'` : gFontData.family
      return new FontFace(family, srcURL, fontStyles).load().then((loadedFace) => {
        document.fonts.add(loadedFace)
      })
    })
    return Promise.all(fontPromises)
  })
  return Promise.all(gFontsPromises)
}

function loadFontByFontItem(fontItemToLoad: any, fontData: FontData) {
  const fontPromises = fontItemToLoad.variants
    .filter((v: string) => {
      return (
        v === 'regular' ||
        (fontData.style === 'italic' && v === 'italic') ||
        (fontData.weight === 'bold' && v === '700')
      )
    })
    .map((fontVariant: string) => {
      const fontStyles = mapVariantToStyles(fontVariant)
      const srcURL = `url(${fontItemToLoad.files[fontVariant]})`
      const family = navigator.userAgent.includes('Firefox') ? `'${fontItemToLoad.family}'` : fontItemToLoad.family
      return new FontFace(family, srcURL, fontStyles).load().then((loadedFace) => {
        document.fonts.add(loadedFace)
      })
    })
  return Promise.all(fontPromises)
}

function mapVariantToStyles(variant: any) {
  const regex = /(\d*)([a-zA-z]*)/
  let [, weight, style] = variant.match(regex)
  weight = weight || '400'
  style = style === 'regular' ? 'normal' : style || 'normal'
  return {
    weight,
    style,
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      loadGoogleFonts,
      loadFontByFontItem,
    },
  }
})
