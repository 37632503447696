import { defaultChapter, defaultSceneLayout } from 'adificial-common/dist/defaults'

export default () => ({
  baseURL: '',
  chapter: undefined,
  sceneLayout: defaultSceneLayout(),
  initialContent: defaultChapter(),
  voiceOverState: {
    isLoading: false,
    errorMessage: '',
  },
  chapterAudioState: {
    isLoading: false,
    errorMessage: '',
  },
  usePreviewDefaults: false,
  audioRequest: { status: 'SUCCESS' },
})
