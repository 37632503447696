import { CONTENT_TYPE } from 'adificial-common/dist/enums/adstudio'
import { fetchChapter, fetchSceneLayout } from './api'

export default {
  fetchGetChapter(store, body) {
    return fetchChapter(store, body)
  },
  fetchGetSceneLayout(store, payload) {
    return fetchSceneLayout(store, payload)
  },
  deactivateAll({ dispatch }) {
    // Omitting the element deactivates them all
    dispatch('deactivateAllElementsBut')
  },
  deactivateAllElementsBut({ commit, getters, state }, elementToKeepActive) {
    switch (getters.contentType) {
      case CONTENT_TYPE.CHAPTER:
        getters.activeScene?.elements.forEach((el) =>
          elementToKeepActive && elementToKeepActive.id === el.id ? null : commit('deactivateElement', el),
        )
        break
      case CONTENT_TYPE.SCENE_LAYOUT:
        state.sceneLayout.elements.forEach((el) =>
          elementToKeepActive && elementToKeepActive.id === el.id ? null : commit('deactivateElement', el),
        )
        break
      default:
        break
    }
  },
  setText({ commit, getters, dispatch }, { textElementId, newText, persist }) {
    const element = getters.getElementById(textElementId)
    if (element?.type === ELEMENT_TYPE.TEXT) {
      const updateAttributeHtml = {
        element,
        attributeValue: newText,
        attribute: 'html',
      }
      commit('setElementAttribute', updateAttributeHtml)

      const isDynamic = newText.includes('{{')
      const updateAttributeObj = {
        element,
        attributeValue: isDynamic,
        attribute: 'dynamic',
      }
      commit('setElementAttribute', updateAttributeObj)
      // Do not wait so this doesn't block the action from being completed
      if (persist) dispatch('correctTextContainer', { textElementId })
    }
  },
  correctTextContainer({ commit, getters }, { textElementId }) {
    return doubleRafPromise().then(() => {
      const textElement = getters.getElementById(textElementId)
      const textElementContainer = document.querySelector('#AD_text_' + textElement.id)

      let newWidth, newHeight

      if (textElementContainer.childNodes.length === 0 || textElementContainer.children.item(0)?.tagName === 'BR') {
        const minElementWidthPx = 100
        const minElementHeightPx = 100
        newWidth = minElementWidthPx
        newHeight = minElementHeightPx
      } else {
        // Check if the content width could be reduced
        textElementContainer.style.height = 'auto'
        textElementContainer.style.width = 'max-content'

        newWidth = textElement.width
        textElementContainer.style.removeProperty('width')
        newHeight = textElementContainer.scrollHeight
      }

      commit('setElementSizeAndPosition', {
        element: textElement,
        width: newWidth,
        height: newHeight,
        x: textElement.x,
        y: textElement.y,
      })
    })
  },
}
