import { useToast } from 'vue-toastification'
import { defaultChapter, defaultSceneLayout } from 'adificial-common/dist/defaults'
import { ANIMATION_MOTION_SMOOTHING_TYPES, ANIMATION_INTENSITY_TYPES } from 'adificial-common/dist/enums/adstudio'

const toast = useToast()
const DEFAULT_POST_OPTIONS = {
  method: 'POST',
  mode: 'cors',
  headers: {
    Authorization: 'null',
    'Content-Type': 'application/json',
  },
}

const updateAnimationsFormat = (content) => {
  // To handle both chapters and scene layouts
  const scenes = content.scenes ? content.scenes : [content]
  scenes.forEach((scene) => {
    scene.elements.forEach((element) => {
      if (element.animation !== undefined) {
        element.animationsTimeline = [element.animation]
        delete element.animation
      }

      if (element.animationsTimeline !== undefined) {
        element.animationsTimeline.forEach((animation) => {
          if (
            animation.timingFunction === 'linear' &&
            animation.motionSmoothing === undefined &&
            animation.intensity === undefined
          ) {
            animation.motionSmoothing = ANIMATION_MOTION_SMOOTHING_TYPES.IN_OUT
            animation.intensity = ANIMATION_INTENSITY_TYPES.MEDIUM
            delete animation.timingFunction
          }
        })
      }
    })
  })
}

const updateContentStructureInPlace = (content) => {
  updateAnimationsFormat(content)
  return content
}

export const IMAGES_PER_PAGE = 30

export const fetchChapter = ({ commit, state }, body) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    body: JSON.stringify(body),
  }

  return fetch(`${state.baseURL}/chapters/get`, options)
    .then((response) => response.json())
    .catch(() => {
      toast.error('API Error')
    })
    .then(({ data, statusCode }) => {
      if (statusCode !== 201) {
        toast.error('Chapter not found')
      } else {
        let chapter
        const contentLocalSave =
          localStorage.getItem('contentLocalSave') && JSON.parse(localStorage.getItem('contentLocalSave'))

        if (
          contentLocalSave &&
          contentLocalSave.chapterId === data.id &&
          contentLocalSave.update_at >= data.update_at &&
          window?.location?.href?.includes('/adstudio')
        ) {
          // If there is a local copy and the local copy is more recent than the one available online
          chapter = contentLocalSave
        } else {
          chapter = data
        }

        chapter.chapterId = chapter.id ?? chapter.chapterId
        delete chapter.id

        chapter?.scenes?.forEach((scene) => {
          scene.timeoutId = undefined
        })

        // This adds some properties to the chapter that are not being stored in the backend. E.g transform property
        // so there are no missing properties when loading a chapter
        const sanitizedChapter = {
          ...defaultChapter(),
          ...chapter,
        }

        updateContentStructureInPlace(sanitizedChapter)

        commit('setChapter', sanitizedChapter)
        commit('setActiveScene', 0)
        commit('setInitialContent', sanitizedChapter)
      }
    })
}

export const fetchSceneLayout = ({ commit, state }, { id }) => {
  const options = {
    ...DEFAULT_POST_OPTIONS,
    body: JSON.stringify({ id }),
  }

  fetch(`${state.baseURL}/chapters/getSceneLayout`, options)
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      const sceneLayout = response.data

      const sanitizedSceneLayout = {
        ...defaultSceneLayout(),
        ...sceneLayout.sceneObject,
        id: sceneLayout.id,
        sceneName: sceneLayout.sceneName,
      }

      updateContentStructureInPlace(sanitizedSceneLayout)

      commit('setSceneLayout', sanitizedSceneLayout)
      if (state.isEditing) {
        commit('setActiveScene', 0)
      }
      return sanitizedSceneLayout
    })
    .catch((e) => {
      console.log(e)
      commit('setSceneLayout', defaultSceneLayout())
      toast.error('API Error')
    })
}
