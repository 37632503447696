import { Action, createStore } from 'vuex'
import globalActions from '@/store/global/actions'
import globalMutations from '@/store/global/mutations'
import globalGetters from '@/store/global/getters'
import globalState from '@/store/global/state'
/* Content Player */
import adActions from '@/store/adstudio/actions'
import adGetters from '@/store/adstudio/getters'
import adMutations from '@/store/adstudio/mutations'
import adState from '@/store/adstudio/state'
/* Adstudio */
import editorActions from '@/store/editor/actions'
import editorGetters from '@/store/editor/getters'
import editorMutations from '@/store/editor/mutations'
import editorState from '@/store/editor/state'

export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore for providing access to the auth module in the editor actions
  const auth = nuxtApp.$auth
  const store = createStore({
    modules: {
      global: {
        namespaced: true,
        actions: globalActions,
        mutations: globalMutations,
        getters: globalGetters,
        state: { globalState },
      },
      adstudio: {
        namespaced: true,
        actions: adActions,
        getters: adGetters,
        mutations: adMutations,
        state: adState,
      },
      editor: {
        namespaced: true,
        actions: editorActions,
        getters: editorGetters,
        mutations: editorMutations,
        state: editorState,
      },
    },
  })

  nuxtApp.vueApp.use(store)
  store.commit('global/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  store.commit('adstudio/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  store.commit('editor/setBaseURL', nuxtApp.$config.public.API_BASE_URL)
  return {
    provide: {
      store,
    },
  }
})
