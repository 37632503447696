import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()

  vueApp.use(VueGtag, {
    property: {
      id: config.public.GOOGLE_ANALYTICS_ID,
      params: {
        send_page_view: false,
      },
    },
  })

  trackRouter(useRouter())
})
