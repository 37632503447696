export default {
  setPURLData(state, purlData) {
    state.purlData = purlData
  },
  setIsFetching(state, value) {
    state.isFetching = value
  },
  setBaseURL(state, url) {
    state.baseURL = url
  },
  setLandingPage(state, landing) {
    state.landingPage = landing
  },
  setLandingPageLoaded(state, loaded) {
    state.landingPageLoaded = loaded
  },
  setFavicons(state, favicons) {
    state.favicons = favicons
  },
  setAuth(state, auth) {
    state.auth = auth
  },
}
